import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { Tune, Search, ExpandLess, ExpandMore } from "@mui/icons-material";
import { useLocalStorage } from "../../hooks/use-local-storage";
import { Divider } from "@mui/material";
import { RemoveIcon } from "../../assets";

interface FilterOption {
  id: string;
  label: string;
  options?: UseCaseOption[];
  prompt: string;
}

interface UseCaseOption {
  id: string;
  label: string;
  prompt: string;
}

const commonUseCases: UseCaseOption[] = [
  {
    id: "summarize",
    label: "Summarize Legal Texts",
    prompt: "How can I summarize legal texts effectively?",
  },
  {
    id: "precedents",
    label: "Find Precedents",
    prompt: "How can I find legal precedents efficiently?",
  },
  {
    id: "contracts",
    label: "Draft Contracts",
    prompt: "What are the best practices for drafting contracts?",
  },
  {
    id: "review",
    label: "Review Contracts",
    prompt: "How can I review contracts thoroughly?",
  },
  {
    id: "explain",
    label: "Explain Legal Concepts to a Client",
    prompt: "How can I explain legal concepts to a client clearly?",
  },
];

const documentManagementUseCases: FilterOption[] = [
  {
    id: "organize-legal-documents",
    label: "Organize Legal Documents",
    prompt: "What are the best practices for organizing legal documents?",
  },
  {
    id: "search-legal-files",
    label: "Search Legal Files",
    prompt: "How can I search legal files efficiently?",
  },
  {
    id: "store-legal-documents-securely",
    label: "Store Legal Documents Securely",
    prompt: "What are the best practices for storing legal documents securely?",
  },
  {
    id: "manage-document-versions",
    label: "Manage Document Versions",
    prompt: "How can I manage document versions effectively?",
  },
  {
    id: "automate-document-indexing",
    label: "Automate Document Indexing",
    prompt: "What tools can I use to automate document indexing?",
  },
  {
    id: "pdf-extraction",
    label: "PDF Extraction",
    prompt: "How can I extract information from PDFs efficiently?",
  },
  {
    id: "document-collaboration",
    label: "Document Collaboration",
    prompt: "What are the best practices for document collaboration?",
  },
  {
    id: "create-document-templates",
    label: "Create Document Templates",
    prompt: "How can I create effective document templates?",
  },
  {
    id: "legal-file-encryption",
    label: "Legal File Encryption",
    prompt: "What are the best practices for encrypting legal files?",
  },
  {
    id: "audit-document-changes",
    label: "Audit Document Changes",
    prompt: "How can I audit document changes effectively?",
  },
];

const caseStrategyUseCases: FilterOption[] = [
  {
    id: "find-relevant-case-law",
    label: "Find Relevant Case Law",
    prompt: "How can I find relevant case law efficiently?",
  },
  {
    id: "track-legal-trends",
    label: "Track Legal Trends",
    prompt: "How can I track legal trends effectively?",
  },
  {
    id: "build-case-strategy",
    label: "Build Case Strategy",
    prompt: "What are the best practices for building a case strategy?",
  },
  {
    id: "research-court-decisions",
    label: "Research Court Decisions",
    prompt: "How can I research court decisions thoroughly?",
  },
  {
    id: "litigation-risk-analysis",
    label: "Litigation Risk Analysis",
    prompt:
      "What are the best practices for conducting litigation risk analysis?",
  },
  {
    id: "identify-legal-experts",
    label: "Identify Legal Experts",
    prompt: "How can I identify legal experts effectively?",
  },
  {
    id: "analyze-legal-precedents",
    label: "Analyze Legal Precedents",
    prompt: "What are the best practices for analyzing legal precedents?",
  },
  {
    id: "predict-case-outcomes",
    label: "Predict Case Outcomes",
    prompt: "How can I predict case outcomes accurately?",
  },
  {
    id: "strategy-based-on-legal-research",
    label: "Strategy Based on Legal Research",
    prompt: "How can I develop a strategy based on legal research?",
  },
  {
    id: "support-litigation-decisions",
    label: "Support Litigation Decisions",
    prompt: "What are the best practices for supporting litigation decisions?",
  },
];

const clientInteractionUseCases: FilterOption[] = [
  {
    id: "client-record-management",
    label: "Client Record Management",
    prompt: "What are the best practices for managing client records?",
  },
  {
    id: "client-communication-automation",
    label: "Client Communication Automation",
    prompt: "How can I automate client communication effectively?",
  },
  {
    id: "track-client-interactions",
    label: "Track Client Interactions",
    prompt: "What are the best practices for tracking client interactions?",
  },
  {
    id: "send-legal-updates-to-client",
    label: "Send Legal Updates to Client",
    prompt: "How can I send legal updates to clients efficiently?",
  },
  {
    id: "manage-client-agenda",
    label: "Manage Client Agenda",
    prompt: "What are the best practices for managing a client agenda?",
  },
  {
    id: "respond-to-client-queries",
    label: "Respond to Client Queries",
    prompt: "How can I respond to client queries effectively?",
  },
  {
    id: "client-consultation-scheduling",
    label: "Client Consultation Scheduling",
    prompt: "What are the best practices for scheduling client consultations?",
  },
  {
    id: "personalized-client-reports",
    label: "Personalized Client Reports",
    prompt: "How can I create personalized client reports?",
  },
  {
    id: "client-case-status-updates",
    label: "Client Case Status Updates",
    prompt: "How can I provide client case status updates effectively?",
  },
  {
    id: "client-communication-history",
    label: "Client Communication History",
    prompt:
      "What are the best practices for maintaining client communication history?",
  },
];

const workflowAutomationUseCases: FilterOption[] = [
  {
    id: "automate-legal-tasks",
    label: "Automate Legal Tasks",
    prompt: "What are the best practices for automating legal tasks?",
  },
  {
    id: "automate-document-generation",
    label: "Automate Document Generation",
    prompt: "How can I automate document generation effectively?",
  },
  {
    id: "automate-client-communications",
    label: "Automate Client Communications",
    prompt: "What are the best practices for automating client communications?",
  },
  {
    id: "workflow-task-tracking",
    label: "Workflow Task Tracking",
    prompt: "How can I track workflow tasks efficiently?",
  },
  {
    id: "automate-reminder-notifications",
    label: "Automate Reminder Notifications",
    prompt:
      "What are the best practices for automating reminder notifications?",
  },
  {
    id: "automate-case-deadlines",
    label: "Automate Case Deadlines",
    prompt: "How can I automate case deadlines effectively?",
  },
  {
    id: "automate-time-entries",
    label: "Automate Time Entries",
    prompt: "What are the best practices for automating time entries?",
  },
  {
    id: "automate-billing-and-invoicing",
    label: "Automate Billing and Invoicing",
    prompt: "How can I automate billing and invoicing effectively?",
  },
  {
    id: "streamline-legal-approval-process",
    label: "Streamline Legal Approval Process",
    prompt:
      "What are the best practices for streamlining the legal approval process?",
  },
  {
    id: "automate-internal-communication",
    label: "Automate Internal Communication",
    prompt: "How can I automate internal communication effectively?",
  },
];

const filterOptionsWithPrompts: FilterOption[] = [
  {
    id: "common-use-cases",
    label: "Common Use Cases",
    options: commonUseCases,
    prompt: "Select a common use case to get started.",
  },
  {
    id: "document-management",
    label: "Document Management",
    options: documentManagementUseCases,
    prompt: "Select a document management use case to get started.",
  },
  {
    id: "case-strategy",
    label: "Case Strategy and Research",
    options: caseStrategyUseCases,
    prompt: "Select a case strategy and research use case to get started.",
  },
  {
    id: "client-interaction",
    label: "Client Interaction",
    options: clientInteractionUseCases,
    prompt: "Select a client interaction use case to get started.",
  },
  {
    id: "workflow-automation",
    label: "Workflow Automation",
    options: workflowAutomationUseCases,
    prompt: "Select a workflow automation use case to get started.",
  },
];

export function FilterDropdown() {
  const [expand, setExpand] = React.useState(true);
  const [selectedFilters, setSelectedFilters] = React.useState<string[]>([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [selectedCases, setSelectedCases] = React.useState<string[]>([]);
  const [useCaseDropdownOpen, setUseCaseDropdownOpen] = React.useState(false);
  const [filterDropdownOpen, setFilterDropdownOpen] = React.useState(false);
  const [activeCollection, setActiveCollection] = useState<string | null>(null);
  const [selectedPrompt, setSelectedPrompt] = useState<string | null>(null);
  const [selectedPrompts, setSelectedPrompts] = useLocalStorage(
    "selectedPrompts",
    ""
  );
  const [selectedUsecases, setSelectedUsecases] = useLocalStorage(
    "selectedCases",
    [""]
  );

  const collectionRefs = useRef<Map<string, HTMLDivElement>>(new Map());

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const collectionName = entry.target.id;

          if (entry.isIntersecting) {
            setActiveCollection(collectionName);
          } else {
            if (activeCollection === collectionName) {
              setActiveCollection(null);
            }
          }
        });
      },
      {
        rootMargin: "0px 0px -80% 0px",
      }
    );

    collectionRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      collectionRefs.current.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
      observer.disconnect();
    };
  }, [activeCollection]);

  const handleFilterSelect = (filterId: string) => {
    if (selectedFilters.includes(filterId)) {
      setSelectedFilters(selectedFilters.filter((id) => id !== filterId));
    } else {
      setSelectedFilters([...selectedFilters, filterId]);
    }
    setSelectedCases([]);
  };

  const filteredUseCases = filterOptionsWithPrompts
    .filter(
      (filter) =>
        selectedFilters.length === 0 || selectedFilters.includes(filter.id)
    )
    .flatMap((filter) => filter.options || [])
    .filter((useCase) =>
      useCase.label.toLowerCase().includes(searchQuery.toLowerCase())
    );

  const toggleUseCaseSelection = (id: string) => {
    const selectedUseCase = filteredUseCases.find(
      (useCase) => useCase.id === id
    );
    if (selectedUseCase) {
      setSelectedPrompt(selectedUseCase.prompt);
    }
    if (selectedCases.includes(id)) {
      setSelectedCases(selectedCases.filter((caseId) => caseId !== id));
    } else {
      setSelectedCases([...selectedCases, id]);
    }
  };

  const filteredCollections = filterOptionsWithPrompts
    .filter(
      (filter) =>
        selectedFilters.length === 0 || selectedFilters.includes(filter.id)
    )
    .map((filter) => {
      const filteredUseCases = (filter.options || []).filter((useCase) =>
        useCase.label.toLowerCase().includes(searchQuery.toLowerCase())
      );
      return { collectionName: filter.label, useCases: filteredUseCases };
    })
    .filter((collection) => collection.useCases.length > 0);

  const handleApply = () => {
    setUseCaseDropdownOpen(true);
    setFilterDropdownOpen(false);
  };

  const handleUseCaseSelect = (useCaseId: string, prompt: string) => {
    if (selectedCases.includes(useCaseId)) {
      setSelectedCases(selectedCases.filter((id) => id !== useCaseId));
    } else {
      setSelectedCases([...selectedCases, useCaseId]);
    }

    // Generate a single prompt based on selected use cases
    const updatedCases = selectedCases.includes(useCaseId)
      ? selectedCases.filter((id) => id !== useCaseId)
      : [...selectedCases, useCaseId];

    const selectedPrompts = filterOptionsWithPrompts
      .flatMap(
        (filter) =>
          filter.options
            ?.filter((option) => updatedCases.includes(option.id))
            .map((option) => option.prompt) || []
      )
      .join(" ");

    setSelectedPrompt(selectedPrompts);
    setSelectedPrompts(selectedPrompts);
    setSelectedUsecases(updatedCases);
  };

  return (
    <div className="max-w-[28.125rem] w-full self-center text-text-color bg-gray-200 py-[0.7rem] px-4 rounded-lg flex flex-col gap-[1rem]">
      {expand && (
        <div className="flex items-center gap-2">
          <p className="tracking-[-0.03rem] text-color p-0 m-0 text-[0.75rem]">
            Filter by :
          </p>
          <span className="rounded-full border border-gray-700 bg- px-2 py-0.5 text-sm">
            <p className="tracking-[-0.03rem] m-0 text-color text-[0.75rem] px-2 py-[0.10rem] rounded-[6.25rem] border border-secondary-text bg-gray-800">
              {selectedFilters.length > 0
                ? filterOptionsWithPrompts
                    .filter((filter) => selectedFilters.includes(filter.id))
                    .map((filter) => filter.label)
                    .join(", ")
                : "All"}
            </p>
          </span>
        </div>
      )}

      <div className="flex items-center justify-between  p-0 m-0">
        {selectedCases.length < 1 ? (
          <p className="text-secondary-text p-0 m-0 text-[1rem]">
            Select Prompt to apply..
          </p>
        ) : (
          <div className="flex flex-wrap gap-2">
            {selectedCases.map((useCaseId: string) => {
              const selectedUseCase = filteredUseCases.find(
                (useCase) => useCase.id === useCaseId
              );
              return (
                selectedUseCase && (
                  <div
                    key={useCaseId}
                    className="relative flex items-center bg-primary-inside py-[0.375rem] px-[1rem] border border-stroked rounded-[6.25rem]"
                    style={{
                      border: "1px solid rgba(255, 255, 255, 0.06)",
                    }}
                  >
                    <button
                      className="absolute top-[-0.3rem] right-0  bg-transparent border-none text-white cursor-pointer"
                      onClick={() => {
                        setSelectedCases(
                          selectedCases.filter((id) => id !== useCaseId)
                        );
                        setSelectedPrompt("");
                      }}
                    >
                      <RemoveIcon />
                    </button>
                    <label htmlFor={useCaseId} className="text-sm font-normal ">
                      {selectedUseCase.label}
                    </label>
                  </div>
                )
              );
            })}
          </div>
          // ends here
        )}

        <div className="flex justify-center items-center gap-2">
          <Tune
            className="h-4 w-4"
            onClick={() => {
              setFilterDropdownOpen(true);
              setUseCaseDropdownOpen(false);
            }}
          />

          <Divider orientation="vertical" flexItem />
          <div
            className="cursor-pointer flex items-center"
            onClick={() => setExpand(!expand)}
          >
            {expand ? <ExpandLess /> : <ExpandMore />}
          </div>
        </div>
      </div>
      {expand && (
        <>
          <Divider className="my-2" />

          <div className="flex items-center justify-between bg-gray-900 py-[0.5rem] px-[0.75rem] rounded-[0.625rem]">
            <input
              type="text"
              placeholder="Search"
              className="outline-none focus:outline-none bg-transparent text-text-color text-[1rem]"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Search />
          </div>

          {filterDropdownOpen && (
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <span className="text-start text-[0.75rem] text-stroked">
                  Filter by
                </span>
                <div className="flex gap-[1.2rem] items-center">
                  <button
                    className="hover:underline bg-transparent text-stroked"
                    onClick={() => {
                      setSelectedFilters([]);
                    }}
                  >
                    Clear
                  </button>

                  <button
                    className="bg-primary-inside text-text-color px-[1.25rem] py-[0.375rem] rounded-[6.25rem] border"
                    onClick={handleApply}
                  >
                    Apply
                  </button>
                </div>
              </div>
              <div className="max-h-32 space-y-2 overflow-y-auto custom-scrollbar">
                {filterOptionsWithPrompts.map((filter) => (
                  <div key={filter.id} className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      className="form-checkbox bg-gray-700 rounded-[0.25rem] border border-white text-stroked focus:outline-none focus:border-0 h-[1.125rem] w-[1.125rem]"
                      id={filter.id}
                      checked={selectedFilters.includes(filter.id)}
                      onChange={() => handleFilterSelect(filter.id)}
                    />
                    <label htmlFor={filter.id} className="text-sm font-normal">
                      {filter.label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}

          {useCaseDropdownOpen && (
            <div className="space-y-4 max-h-32 overflow-y-scroll relative custom-scrollbar">
              {filteredCollections.map((collection) => (
                <div
                  key={collection.collectionName}
                  className="text-start relative"
                >
                  <div
                    id={collection.collectionName}
                    ref={(el) =>
                      collectionRefs.current.set(
                        collection.collectionName,
                        el!!
                      )
                    }
                    className={`text-start text-stroked block w-full text-[0.75rem] sticky top-0 bg-gray-200 z-10 ${
                      activeCollection === collection.collectionName
                        ? "font-semibold"
                        : ""
                    }`}
                  >
                    {collection.collectionName}
                  </div>

                  <div className="space-y-2 mt-4">
                    {collection.useCases.length > 0 ? (
                      collection.useCases.map((useCase) => (
                        <div
                          key={useCase.id}
                          className="flex items-center space-x-2"
                        >
                          <input
                            type="checkbox"
                            className="form-checkbox bg-gray-700 rounded-[0.25rem] border border-white text-stroked focus:outline-none focus:border-0 h-[1.125rem] w-[1.125rem]"
                            id={useCase.id}
                            checked={selectedCases.includes(useCase.id)}
                            onChange={() =>
                              handleUseCaseSelect(useCase.id, useCase.prompt)
                            }
                          />
                          <label
                            htmlFor={useCase.id}
                            className="text-sm font-normal"
                          >
                            {useCase.label}
                          </label>
                        </div>
                      ))
                    ) : (
                      <span className="text-sm text-gray-400">
                        No use cases found.
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
}
