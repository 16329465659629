// this code is responsible to generate document from form input
import { marked } from "marked";
class DocumentGenerator {
  private openAIKey: string;
  private document: string;
  private city: string;
  private completedData: any;

  constructor(
    openAIKey: string,
    document: string,
    city: string,
    completedData: any
  ) {
    this.openAIKey = openAIKey;
    this.document = document;
    this.city = city;
    this.completedData = completedData;
  }

  private async fetchOpenAIResponse(messages: any, maxTokens: number) {
    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.openAIKey}`,
      },
      body: JSON.stringify({
        model: "gpt-4o-mini",
        messages: messages,
        max_tokens: maxTokens,
      }),
    });

    const data = await response.json();
    if (
      data.choices &&
      data.choices[0].message &&
      data.choices[0].message.content
    ) {
      return data.choices[0].message.content;
    } else {
      throw new Error("Failed to retrieve response from OpenAI");
    }
  }

  private async getList() {
    console.log("Fetching list of document sections");
    const messages = [
      { role: "system", content: "You are a helpful assistant." },
      {
        role: "user",
        content: `We need to generate a legal document for ${
          this.document
        } in ${this.city}.
                    Data:${JSON.stringify(this.completedData)}.
                    Return just a plain JSON array with a list of section titles.`,
      },
    ];

    const rawContent = await this.fetchOpenAIResponse(messages, 500);
    const cleanedContent = rawContent.replace(/```json\s*|\s*```/g, "").trim();
    try {
      return JSON.parse(cleanedContent); // Parse section list
    } catch (error) {
      console.error("Error parsing JSON:", error);
      throw new Error("Failed to parse section list");
    }
  }

  private async getSectionText(sectionTitle: string, sectionList: string[]) {
    console.log(`Generating content for section: ${sectionTitle}`);
    const sectionTitles = sectionList.join("\n");

    // console.log(`If any information is missing, put this placeholder in its place ==========
    //         Do not use [Insert XXX] as placeholders
    //          Use the following data to fill in any missing details as accurately as possible:

    //         Data Context:
    //         ${JSON.stringify(this.completedData)}

    //         Here are the instructions:
    //         - Replace any placeholder like "==========" with the corresponding data if available in the context provided.
    //         - If some data is missing and you cannot infer it, then only use "==========".

    //         Write content for this section: ${sectionTitle}
    //         Do not write content for any of these sections:
    //         ${sectionTitles}

    //         Write it as comprehensively as possible.
    //         Return "document" in markdown format.
    //         Output only markdown, no other text.
    //         Assume this document is final, don't any comments.
    //         Don't write out any missing information`);

    const messages = [
      {
        role: "system",
        content:
          "You are a helpful assistant that generates detailed content for legal documents by using available data. You should aim to fill in any missing details with relevant information when available, and use placeholders only if absolutely necessary.",
      },
      {
        role: "user",
        content: `If any information is missing, put this placeholder in its place ==========
                    Do not use [Insert XXX] as placeholders
                     Use the following data to fill in any missing details as accurately as possible:

                    Data Context:
                    ${JSON.stringify(this.completedData)}

                    Here are the instructions:
                    - Replace any placeholder like "==========" with the corresponding data if available in the context provided.
                    - If some data is missing and you cannot infer it, then only use "==========".
                    

                    Write content for this section: ${sectionTitle}
                    Do not write content for any of these sections:
                    ${sectionTitles}

                    Write it as comprehensively as possible.
                    Return "document" in markdown format. 
                    Output only markdown, no other text. 
                    Assume this document is final, don't any comments.
                    Don't write out any missing information`,
      },
    ];

    const response = await this.fetchOpenAIResponse(messages, 1400);
    console.log("Response from OpenAI:", response);

    // Remove specific markdown tags and content enclosed within triple backticks
    const cleanedResponse = response
      .replace(/```markdown/g, "") // Remove ```markdown
      .replace(/```/g, ""); // Remove ```

    return cleanedResponse;
  }

  public async generateDocumentText() {
    try {
      const sectionList = await this.getList();
      const firstSection = await this.getSectionText(
        sectionList[0],
        sectionList
      );

      const restOfSections = await Promise.all(
        sectionList
          .slice(1)
          .map((section: string) => this.getSectionText(section, sectionList))
      );

      // Remove any enclosing markdown tags from each section
      const cleanedSections = restOfSections.map((section) => section.trim());

      // Concatenate the sections into a single markdown document
      const markdownText = `${firstSection.trim()}\n\n${cleanedSections.join(
        "\n\n"
      )}`;

      // Convert the concatenated markdown text to HTML
      // const htmlText = marked(markdownText);

      // return htmlText;
      return markdownText;
    } catch (error) {
      console.error("Error during document generation:", error);
      throw new Error("Failed to generate the complete document");
    }
  }
}

export default DocumentGenerator;
