import { useContext, useRef, useState, lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import {
  DeleteIcon,
  DownloadIcon,
  ImgLineHorizontal,
  ImgLogo,
} from "../../assets";
import { useNavigate } from "react-router-dom";
import ProgressSteps from "../../components/progress/progress-steps.component";
import { AppContext } from "../../context/AppContext";
import { AppRoute } from "../../routes";

// Lazy load the DocumentPreview component
const DocumentPreview = lazy(
  () => import("../../components/pdf/document-preview")
);

export function PdfPage() {
  const [error, setError] = useState("");
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const documentRef = useRef<HTMLDivElement | null>(null);

  const { questionSections, generatedMarkdown, setCurrentStep } = appContext!!;

  // const handleDownloadPdf = async () => {
  //   console.log("Generating PDF ....");
  //   console.log(documentRef.current?.innerHTML);

  //   if (documentRef.current) {
  //     try {
  //       // dynamically import jspdf to avoid loading it on initial page load
  //       const { default: jsPDF } = await import("jspdf");

  //       const pdf = new jsPDF("p", "mm", "a4");

  //       await pdf.html(documentRef.current, {
  //         margin: [10, 10, 10, 10],
  //         autoPaging: "text",
  //         x: 10,
  //         y: 10,
  //         html2canvas: {
  //           scale: 0.21, // Adjust scale for better clarity
  //         },
  //         callback: (pdf) => {
  //           pdf.save(
  //             `${
  //               appContext?.documentContent || "Lease_Agreement"
  //             } (AI Frank).pdf`
  //           );
  //         },
  //       });

  //       console.log("PDF generated successfully.");
  //     } catch (error) {
  //       console.error("Error generating PDF:", error);
  //       setError("Error generating PDF. Please try again.");
  //     }
  //   } else {
  //     setError(
  //       "Document preview is not ready. Ensure the content is loaded before downloading."
  //     );
  //   }
  // };

  const handleDeleteForm = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      <Helmet>
        <title>AI Frank | Home</title>
        <meta name="description" content="" />
      </Helmet>

      <div
        data-id="container"
        className="w-full text-center text-[1.25rem] text-text-color font-poppins overflow-hidden"
      >
        <div
          data-id="top-glow"
          className="[filter:blur(500px)] rounded-[50%] bg-primary-inside border-stroked border-[1px] border-solid box-border w-[50rem] h-[30rem] mb-[-120px] mx-auto mt-[-20rem]"
        />
        <nav
          className="text-center md:text-left md:ml-[2.5rem]"
          onClick={() => navigate(AppRoute.Dashboard)}
        >
          <ImgLogo data-id="nav-logo" className="w-[8.5rem] h-[2rem]" />
        </nav>
        {questionSections.length > 0 && (
          <div className="px-[1rem] md:px-[3rem] xl:px-[10rem] py-[3rem]">
            <div className="w-full max-w-[130rem] mx-auto mb-[5rem] [backdrop-filter:blur(20px)] rounded-[24px] p-[1rem] md:p-[24px] border-gray-100 border-[1px] border-solid box-border flex flex-col lg:flex-row  gap-[1rem] xl:gap-[2rem] text-[0.875rem] lg:mt-[4rem] xl:p-[2rem]">
              <div className="basis-2/6 [backdrop-filter:blur(20px)] rounded-2xl bg-gray-500 border-gray-100 border-[1px] border-solid box-border flex flex-col self-start p-[1rem] md:p-[1.5rem] gap-[1rem] text-[0.875rem] text-stroked h-full xl:px-[2rem]">
                <div className="text-white text-[20px] self-start">
                  <p>Options</p>
                </div>
                <div
                  className="rounded-xl bg-primary-inside px-[1.2rem] py-[0.5rem] cursor-pointer border-solid border-[1px] border-stroked hover:bg-primary-inside/40 transition-colors duration-300"
                  onClick={() => {
                    navigate(AppRoute.Dashboard);
                  }}
                >
                  <button className="bg-transparent text-white text-[16px] cursor-pointer">
                    Go to Dashboard
                  </button>
                </div>
                <div
                  className="rounded-xl bg-primary-inside px-[1.2rem] py-[0.5rem] cursor-pointer border-solid border-[1px] border-stroked hover:bg-primary-inside/40 transition-colors duration-300"
                  onClick={() => {
                    // handleDownloadPdf();
                    console.log("not implemented");
                  }}
                >
                  <DownloadIcon
                    data-id="download-icon"
                    className="w-[24px] h-[24px] align-bottom"
                  />
                  <button className="bg-transparent text-white text-[16px] cursor-pointer">
                    Download PDF
                  </button>
                </div>
                <div
                  className="rounded-xl bg-transparent px-[1.2rem] py-[0.5rem] cursor-pointer border-solid border-[1px] border-[#E25354] hover:bg-[#E25354]/15 transition-colors duration-300"
                  onClick={handleDeleteForm}
                >
                  <DeleteIcon
                    data-id="delete-icon"
                    className="w-[24px] h-[24px] align-bottom"
                  />
                  <button className="bg-transparent text-[#E25354] text-[16px] cursor-pointer">
                    Delete Form
                  </button>
                </div>
                <ImgLineHorizontal className="self-stretch relative rounded-481xl max-w-full overflow-hidden max-h-full" />
                <div className="text-white text-[20px] self-start">
                  <p>Steps Completed</p>
                </div>
                <ProgressSteps
                  steps={questionSections.map((section, index) => ({
                    label: section.sectionTitle,
                    step: index + 1,
                  }))}
                  currentStep={questionSections.length}
                  hideNavigation
                  direction="vertical"
                  documentCompleted
                />
              </div>
              <div className="basis-4/6  [backdrop-filter:blur(20px)] rounded-2xl bg-gray-500 border-gray-100 border-[1px] border-solid box-border flex flex-col items-center justify-start p-[1rem] md:p-[1.5rem] gap-[1rem] xl:p-[2rem]  text-[0.875rem] text-stroked">
                <div className="rounded-lg bg-primary-inside self-stretch px-[20px] py-[16px] text-white text-[20px]">
                  <p className="text-start m-0">
                    Preview Your AI Generated Document
                  </p>
                </div>
                <ImgLineHorizontal className="self-stretch relative rounded-481xl max-w-full overflow-hidden max-h-full" />
                <div className="max-h-[40rem] lg:max-h-[40rem] overflow-y-scroll scrollbar-hide rounded-lg w-[100%]">
                  <Suspense fallback={<div>Loading...</div>}>
                    <DocumentPreview
                      ref={documentRef}
                      markdownContent={generatedMarkdown}
                    />
                  </Suspense>
                </div>
                {error && (
                  <div>
                    <p className="text-red-500 text-[1rem]">{error}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
