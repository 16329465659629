import React, { useState } from "react";
import {
  PdfIcon,
  ProgressLine,
  ProgressLine1,
  ProgressLine2,
  ProgressLine3,
  StarInputIcon,
  CheckIcon,
  DashIcon,
} from "../../assets";

export const SidebarRight = () => {
  const [progress, setProgress] = useState(10); // Example progress value
  const [status, setStatus] = useState(["analysing", "processing"]); // Example status array

  const getStatusColor = (currentStatus: string) => {
    return status.includes(currentStatus)
      ? "text-stroked"
      : "text-darkslategray";
  };

  const getVectorColor = (currentStatus: string) => {
    return status.includes(currentStatus)
      ? "stroke-stroked"
      : "stroke-darkslategray";
  };

  const isReady = status.includes("ready");

  return (
    <>
      <div
        data-test="sidebar-container"
        className="flex w-full h-full flex-col gap-[1.5rem] font-poppins overflow-x-hidden"
      >
        <h1 className="text-[1rem] text-start">Documents</h1>
        <hr className="w-full bg-gray-600 border-gray-600 border-[1px] border-solid"></hr>

        <div
          data-id="top-glow"
          className="right-glow absolute rounded-b-[50%] bg-primary-inside/50 border-stroked border-[1px] border-solid box-border w-[50rem] h-[15rem] mx-auto top-0"
        />
        <div
          data-id="top-glow"
          className="right-glow absolute rounded-b-[50%] bg-primary-inside/50 border-stroked border-[1px] border-solid box-border w-[50rem] h-[15rem] mx-auto bottom-0"
        />
        {/* uploading doc */}
        <div
          className="border border-gray-700 rounded-[0.75rem] p-[0.75rem] text-start"
          style={{
            border: "1px solid rgba(255, 255, 255, 0.06)",
          }}
        >
          <div className="flex items-center gap-[0.5rem]">
            <PdfIcon className="w-[1.5rem] h-[1.5rem]" />
            <p className="text-[0.875rem]">Document1</p>
          </div>
          <hr className="w-full bg-gray-600 border-gray-600 border-[1px] border-solid"></hr>
          {/* steps */}
          <div>
            <h1 className="text-[0.875rem] text-text-color">
              Generating Progress
            </h1>
            <div className="relative w-full mb-[1rem]">
              <ProgressLine
                className={isReady ? "stroke-stroked" : "stroke-darkslategray"}
              />
              <ProgressLine1
                className={`absolute top-0 left-0 ${getVectorColor(
                  "analysing"
                )}`}
              />
              <ProgressLine2
                className={`absolute top-[0.7rem] left-0 ${getVectorColor(
                  "processing"
                )}`}
              />
              <ProgressLine3
                className={`absolute left-[-0.02rem] bottom-[-0.6rem] ${getVectorColor(
                  "ready"
                )}`}
              />
              <div className="flex flex-col w-[80%] absolute top-[0.7rem] left-[2rem] gap-[1.31rem]">
                <p
                  className={`text-[0.85rem] ${getStatusColor(
                    "analysing"
                  )} flex w-full justify-between m-0 p-0`}
                >
                  Analysing
                  <span className="m-0 p-0 ">
                    {status.includes("analysing") ? (
                      <CheckIcon className="m-0 p-0" />
                    ) : (
                      `${progress}%`
                    )}
                  </span>
                </p>
                <p
                  className={`text-[0.85rem] ${getStatusColor(
                    "processing"
                  )} flex w-full justify-between m-0 p-0`}
                >
                  Processing
                  <span>
                    {status.includes("processing") ? (
                      <CheckIcon />
                    ) : (
                      `${progress}%`
                    )}
                  </span>
                </p>
                <p
                  className={`text-[0.85rem] ${getStatusColor(
                    "ready"
                  )} flex w-full justify-between m-0 p-0`}
                >
                  Ready to download
                  <span>
                    {status.includes("ready") ? <CheckIcon /> : <DashIcon />}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* docs list */}
        <div
          className="border border-gray-700 rounded-[0.75rem] p-[0.75rem] text-start"
          style={{
            border: "1px solid rgba(255, 255, 255, 0.06)",
          }}
        >
          <div className="flex justify-between items-center gap-[0.5rem] ">
            <div className="flex flex-row gap-[0.5rem]">
              <PdfIcon className="w-[1.5rem] h-[1.5rem]" />
              <p className="text-[0.875rem] m-0 p-0">Document1</p>
            </div>

            <StarInputIcon className="w-[1.5rem] h-[1.5rem]" />
          </div>
        </div>
      </div>
    </>
  );
};
