import MenuIcon from "@mui/icons-material/Menu";

import { useContext, useEffect, useRef, useState, lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { StarIcon, ExportIcon } from "../../assets/index";
import { DocumentForm } from "../../components/document/document-form";
import { FilterDropdown } from "../../components/document/smart-prompt-form";
import { Sidebar } from "../../components/sidebar/sidebar";
import { AppContext } from "../../context/AppContext";
import { useAuth } from "../../context/AuthContext";
import { SidebarRight } from "../../components/sidebar/sidebar-right";
import { Chat as ChatComponent } from "../../components/chat/chat";
import { Divider } from "@mui/material";
import { Input } from "../../components/chat/input";
import { Chat, Message } from "../../types/chat.types";
import { useChat } from "../../context/ChatContext";
// import PDFViewer from "../../components/pdf/pdf-viewer";

interface Question {
  question: string;
  type: "textinput" | "textarea" | "checkbox";
  placeholder: string;
}

interface History {
  title: string;
  id: string;
}

export function DashboardPage() {
  const { chats, activeChat, setActiveChat } = useChat();

  const navigate = useNavigate();
  const [newDocument, setNewDocument] = useState("");
  const [smartPrompt, setSmartPrompt] = useState("");
  // chat states
  const [newChat, setNewChat] = useState<boolean>(false);
  const [selectedChat, setSelectedChat] = useState<string | null>(null);

  const [userPrompt, setUserPrompt] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedDocTitle, setSelectedDocTitle] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false); // State for sidebar visibility
  const [tempCity, setTempCity] = useState("");
  const [showDropdown, setShowDropdown] = useState<string | null>();
  const [history, setHistory] = useState<History[]>([]);
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);
  const [tempDocument, setTempDocument] = useState("");
  const [selectedDocument, setSelectedDocument] = useState<string | null>(null); // State for selected document
  const [selectedDocUrl, setSelectedDocUrl] = useState<string | null>(null); // State for selected markdown
  const documentRef = useRef<HTMLDivElement | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null); // Ref for file input
  const sidebarRef = useRef<HTMLDivElement | null>(null); // Ref for sidebar
  const dropdownRef = useRef<HTMLDivElement | null>(null); // Ref for dropdown
  const dropdownRef2 = useRef<HTMLDivElement | null>(null); // Ref for second dropdown
  const [showDropdown2, setShowDropdown2] = useState<boolean | null>();
  const [files, setFiles] = useState<File[] | null>(null);

  const [labels, setLabels] = useState<string[]>([]);
  const [showChat, setShowChat] = useState<boolean>(false);

  const { user } = useAuth();
  const appContext = useContext(AppContext);

  useEffect(() => {
    const activeChatMessages: Message[] =
      chats.find((chat) => chat.id === activeChat)?.messages || [];

    if (activeChatMessages.length > 0) {
      console.log(activeChatMessages);
      setShowChat(true);
      return;
    }

    setShowChat(false);
  }, [chats, activeChat]);

  useEffect(() => {
    if (!user) {
      console.log("no user");
      navigate("/"); // Redirect to home or login page if not authenticated
    }
  }, [user, navigate]); // Add isAuthenticated and navigate as dependencies

  useEffect(() => {
    if (smartPrompt) {
      setSmartPrompt("");
    }
  }, [newDocument]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(null);
        setHoveredItem(null);
      }

      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        setSidebarOpen(false);
      }

      if (
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target as Node)
      ) {
        setShowDropdown2(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>AI Frank | Home</title>
        <meta name="description" content="" />
      </Helmet>

      <div
        data-id="dashboard-container"
        className="w-full flex flex-row h-screen  max-w-full overflow-x-hidden text-center text-[1.25rem] text-text-color font-poppins overflow-hidden "
      >
        {/* Toggle button for sidebar */}
        <div
          className={`md:hidden p-4  h-fit absolute top-4 left-4  z-50 ${
            sidebarOpen ? "hidden" : ""
          }`}
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          <MenuIcon
            sx={{
              color: "white",
              fontSize: 40,
            }}
            onClick={() => setSidebarOpen(!sidebarOpen)}
          />
        </div>

        {/* side bar */}
        <div
          ref={sidebarRef}
          className={`md:bg-gray-500 min-h-[90%]  md:min-h-[calc(100vh)-30px] bg-primary-text border-r-4 pt-[2.5rem] md:min-w-[calc(13.54vw)] flex flex-col items-center px-[1.25rem] border-r-stroked transition-transform duration-300 ${
            sidebarOpen ? "translate-x-0 " : "-translate-x-full"
          } md:translate-x-0 fixed md:relative z-50 md:z-auto`}
        >
          <Sidebar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            setNewChat={setNewChat}
            setSelectedChat={setActiveChat}
          />
        </div>

        {/* main screen */}
        <div className="w-full relative h-screen flex flex-col items-center justify-center gap-[3.5rem]  max-w-full overflow-x-hidden text-center  text-[1.25rem] text-text-color font-poppins overflow-hidden transition ">
          <div
            data-id="top-glow"
            className="right-glow absolute rounded-b-[50%] bg-primary-inside/50  border-stroked border-[1px] border-solid box-border w-[50rem] h-[10rem] mx-auto top-0 
 "
          />
          <div
            data-id="right-glow"
            className="[ right-glow absolute bg-primary-inside/50 w-[calc(50vw)] h-[calc(60vh)] z-[-10] bottom-[-2rem] left-auto right-auto"
          />

          {!selectedDocUrl && (
            <>
              <div className="flex-grow px-[1.5rem] w-full flex flex-col justify-center items-center ">
                <div
                  className={`flex  flex-col items-center   text-[1.125rem]   ${
                    newDocument ? "animate-slide-up" : ""
                  } 
                  ${showChat ? "hidden" : ""}
                  `}
                >
                  <div className="rounded-[30px]  mt-20 md:m-0  bg-primary-inside border-stroked border-[1px] border-solid flex flex-row items-center justify-center py-[0.5rem] px-[2rem]">
                    <div className="relative tracking-[-0.04em] font-medium">
                      AI For Documents
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start gap-[0.5rem] text-[2rem] md:text-[3.5rem] mt-[1rem]">
                    <div className="self-stretch relative tracking-[-0.06em] font-medium">
                      <span>Generate</span>
                      <span className="text-white">{` `}</span>
                      <span className="text-transparent !bg-clip-text [background:linear-gradient(91.15deg,_#707178,_#484950)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]">{`Legal `}</span>
                      <span>Documents</span>
                    </div>
                    <div className="self-stretch relative  text-[1.5rem] md:text-[1.25rem] tracking-[-0.04em] font-medium text-secondary-text mt-[0.5rem]">
                      Legal documents Personalized for your needs.
                    </div>
                  </div>
                </div>

                <div
                  className={` 
                    w-full
                    flex flex-col justify-center
                    ${"animate-slide-up"}  transition duration-500`}
                >
                  <div className="flex items-center self-center">
                    <StarIcon className="w-[2rem] h-[2rem] text-stroked text-lg font-bold" />
                    <h2 className=" text-stroked text-lg font-bold">
                      {" "}
                      Smart Prompt
                    </h2>
                  </div>

                  <FilterDropdown />
                </div>

                {/* display chat component here */}
                {showChat && (
                  <>
                    <hr className="w-full my-[2.5rem] bg-divider border-divider border-[1px] border-solid"></hr>
                    <div className="w-full flex flex-col items-center justify-center gap-[2rem]">
                      <ChatComponent />
                    </div>
                  </>
                )}
              </div>

              {/* input prompt */}
              <Input />
            </>
          )}

          {/* display document */}
          {selectedDocument && (
            <div className="min-h-[calc(100vh)] w-full pt-[1.5rem] px-[2.5rem]">
              <div className="  w-full flex justify-center md:justify-between items-center   ">
                <h1 className="text-[var(--Text-Color,#FDFDFD)] text-[1.4rem] w-[calc(70vw)] text-center md:text-start pl-[1rem] font-poppins md:text-[2rem] text-wrap font-semibold leading-normal tracking-[-0.08rem]">
                  {selectedDocTitle}
                </h1>
                <div className="absolute bottom-0 md:relative flex items-center justify-center gap-x-[1.25rem]">
                  <button
                    className="flex bg-transparent text-white  "
                    onClick={() => {
                      console.log("Edit document");
                    }}
                  ></button>
                  <button
                    className="flex p-[0.5rem] px-[1.5rem] justify-center items-center gap-[0.125rem] self-stretch rounded-[6.25rem] border border-stroked bg-[rgba(99,129,252,0.12)]
                        text-stroked font-poppins text-[0.875rem] font-normal  leading-normal tracking-[-0.035rem] hover:cursor-pointer
                        "
                    onClick={() => {
                      console.log("Exporting document");
                    }}
                  >
                    <div className="flex items-center justify-center gap-[0.25rem]">
                      <ExportIcon className="w-[1rem] h-[1rem]" />
                      Export
                    </div>
                  </button>
                </div>
              </div>
              <hr className="w-full  bg-gray-200 border-gray-600 border-[1px] border-solid mb-[1.5rem]"></hr>
              <div className="h-full overflow-y-scroll scrollbar-hide rounded-lg w-full">
                {/* <DocumentPreview
                  ref={documentRef}
                  markdownContent={selectedMarkdown}
                /> */}
                <object
                  data={selectedDocUrl || ""}
                  type="application/pdf"
                  width="100%"
                  height="100%"
                >
                  <p>
                    Alternative text - include a link{" "}
                    <a href="http://africau.edu/images/default/sample.pdf">
                      to the PDF!
                    </a>
                  </p>
                </object>
              </div>
            </div>
          )}
        </div>

        {/* right sidebar */}
        <div
          ref={sidebarRef}
          className={`md:bg-gray-500 min-h-[90%]  md:min-h-[calc(100vh)-30px] bg-primary-text border-r-4 pt-[2.5rem] md:min-w-[calc(13.54vw)] flex flex-col items-center px-[1.25rem] border-r-stroked transition-transform duration-300 ${
            sidebarOpen ? "translate-x-0 " : "-translate-x-full"
          } md:translate-x-0 fixed md:relative z-50 md:z-auto`}
        >
          <SidebarRight />
        </div>
      </div>
    </>
  );
}
