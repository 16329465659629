import { ApiService } from "./api.service";
import { ApiEndpoint } from "./api.service";
import { CreateDocumentDto } from "../types/document.types";
import { UpdateDocumentDto } from "../types/document.types";
import { Document } from "../types/document.types";

interface DocbyId {
  url: string;
}
class DocumentService {
  // Create a new document
  public async createDocument(data: CreateDocumentDto): Promise<Document> {
    try {
      const response = await ApiService.post<CreateDocumentDto, Document>(
        ApiEndpoint.CreateDocument,
        data
      );
      return response;
    } catch (error) {
      console.error("Failed to create document:", error);
      throw new Error("Unable to create document.");
    }
  }

  public async generateDocument(data: CreateDocumentDto): Promise<void> {
    console.log("data", data);
    try {
      const response = await ApiService.post<CreateDocumentDto, Document>(
        ApiEndpoint.GeneratePdf,
        data
      );
      console.log(response);
    } catch (error) {
      console.error("Failed to generate document:", error);
      throw new Error("Unable to generate document.");
    }
  }

  // Fetch all documents for the current user
  public async getAllDocuments(): Promise<Document[]> {
    let response;
    try {
      response = await ApiService.get<null, Document[]>(
        ApiEndpoint.GetAllDocuments,
        null
      );
      return response;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }

  // Fetch a specific document by ID
  public async getDocumentById(id: string): Promise<DocbyId> {
    try {
      const response = await ApiService.get<null, DocbyId>(
        `${ApiEndpoint.GetDocumentById}/${id}/url`,
        null
      );
      console.log("response from get document by id", response);
      return response;
    } catch (error) {
      console.error(`Failed to fetch document with ID ${id}:`, error);
      throw new Error("Unable to fetch document.");
    }
  }

  // Update a document by ID
  public async updateDocument(
    id: string,
    data: UpdateDocumentDto
  ): Promise<Document> {
    try {
      const response = await ApiService.patch<UpdateDocumentDto, Document>(
        `${ApiEndpoint.UpdateDocument}/${id}`,
        data
      );
      return response;
    } catch (error) {
      console.error(`Failed to update document with ID ${id}:`, error);
      throw new Error("Unable to update document.");
    }
  }

  // Delete a document by ID
  public async deleteDocument(id: string): Promise<{ status: string }> {
    try {
      const response = await ApiService.delete<null, { status: string }>(
        `${ApiEndpoint.DeleteDocument}/${id}`,
        null
      );
      return response;
    } catch (error) {
      console.error(`Failed to delete document with ID ${id}:`, error);
      throw new Error("Unable to delete document.");
    }
  }
}

// Initialize DocumentService instance
export const documentService = new DocumentService();
