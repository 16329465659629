import React, { useState } from "react";
import {
  ImgLogo,
  GoogleIcon,
  AppleIcon,
  StarIcon,
  EyeIcon,
} from "../../assets";
import { SignupData, SuccessSignupResponse } from "../../types/auth.types";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import { authService } from "../../services/auth.service";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "../../routes";
import { useAuth } from "../../context/AuthContext";

enum Routes {
  LOGIN = "/login",
  HOME = "/",
}

export const SignupPage = () => {
  const navigate = useNavigate();
  const { setUser } = useAuth();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    terms: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const handleSignup = async (data: SignupData) => {
    setLoading(true);
    let response: {
      status?: number;
      message?: string;
      id?: string;
      email?: string;
      firstName?: string;
      lastName?: string;
    };
    try {
      response = await authService.register(data);
      console.log("Response", response);
      if (response?.status === 201) {
        console.log("Signup successful.");

        const user = {
          id: (response as SuccessSignupResponse).user.id,
          firstName: (response as SuccessSignupResponse).user.firstName,
          lastName: (response as SuccessSignupResponse).user.lastName,
          email: (response as SuccessSignupResponse).user.email,
        };
        setUser(user);
        navigate(AppRoute.Home);
        return;
      } else {
        setError(response?.message || "Something went wrong");
      }
    } catch (error: any) {
      setError(error?.message);
      console.log("Error", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prev) => ({ ...prev, [name]: checked }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    console.log("signup disabled", process.env.REACT_APP_SIGNUP_DISABLED);

    if (process.env.REACT_APP_SIGNUP_DISABLED === "false") {
      const data: SignupData = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password,
        userType: "end-user",
      };

      if (data.firstName === "" || data.lastName === "" || data.email === "") {
        setError("All fields are required");
        return;
      }
      if (data.password !== formData.confirmPassword) {
        setError("Passwords do not match");
        return;
      }
      if (!formData.terms) {
        setError("Please agree to the terms and privacy policy");
        return;
      }
      if (data.password.length < 8) {
        setError("Password must be at least 8 characters long");
        return;
      }
      await handleSignup(data);
      return;
    }

    setOpen(true);
  };

  // close snackbar
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    setOpen(false);
  };

  return (
    <div className="signup-screen flex flex-col md:flex-row md:h-screen w-full text-white font-poppins">
      {/* left section */}
      <div className="flex w-full md:w-1/2 h-full">
        <div className="border overflow-hidden flex flex-col mx-3 mt-3 rounded-[1.5rem] bg-gray-200 w-full h-[calc(100vh-30px)] backdrop-blur-md">
          <nav className="text-center md:text-left ml-[2.5rem] mt-[2.5rem]">
            <ImgLogo data-id="nav-logo" className="w-[9.75rem] h-[2rem]" />
          </nav>

          <div className="flex flex-grow flex-col justify-center items-center">
            <div className="flex flex-col">
              <StarIcon className="top-[12rem] w-[2.5rem] border-[2px] border-stroked h-[2.5rem] mx-auto bg-primary-inside rounded-[1rem] p-[0.75rem]" />
              <p className="text-text-color text-[1.7rem] md:text-[2.5rem] text-center mb-[10rem]">
                Unlock AI insights, instant
                <br />
                help, and smart tools.
              </p>
            </div>
          </div>
          <div className="absolute bottom-[0rem] min-h-[17.4375rem] w-[17.4375rem] rounded-full bg-stroked/30 blur-[400px]"></div>
        </div>
      </div>

      {/* Right section */}
      <div
        className="flex justify-center items-center md:w-1/2 h-full mx-4"
        data-test="signup-input-container"
      >
        <div className="flex flex-col gap-[1.5rem]">
          <div className="pt-10 flex flex-col gap-y-[-1rem] justify-center items-center">
            <h1 className="p-0 m-0">Create an Account</h1>
            <p className="text-secondary-text tracking-[-0.03rem] p-0 mt-1">
              Sign up to get started with us.
            </p>
          </div>

          <form
            className="border rounded-[1.5rem] h-full"
            onSubmit={handleSubmit}
            data-test="signup-form"
          >
            <div className="flex flex-col relative">
              <div className="flex flex-col md:flex-row gap-[1rem]">
                <div className="flex flex-col flex-1 gap-[0.25rem]">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    data-test="signup-first-name-input"
                    id="firstName"
                    name="firstName"
                    placeholder="Enter first name"
                    value={formData.firstName}
                    onChange={handleChange}
                    className="bg-[#2B2B37] text-secondary-text py-[0.625rem] px-[1rem] rounded-[0.625rem]"
                  />
                </div>

                <div className="flex flex-col flex-1 gap-[0.25rem]">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    data-test="signup-last-name-input"
                    id="lastName"
                    name="lastName"
                    placeholder="Enter last name"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="bg-[#2B2B37] text-secondary-text py-[0.625rem] px-[1rem] rounded-[0.625rem]"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-[0.25rem] my-[1.25rem]">
                <label htmlFor="email" className="tracking-[-0.04rem]">
                  Email
                </label>
                <input
                  type="email"
                  data-test="signup-email-input"
                  id="email"
                  name="email"
                  required
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleChange}
                  className="bg-[#2B2B37] text-secondary-text py-[0.625rem] px-[1rem] rounded-[0.625rem] relative z-10"
                />
              </div>

              <div
                data-id="right-glow"
                className="[filter:blur(896.4px)] absolute bg-primary-inside/50 w-[17.438rem] h-[20.75rem] z-[-2] top-0 right-[5rem]"
              />

              <div className="flex flex-col w-full gap-[0.25rem] relative">
                <label htmlFor="password">Password</label>
                <input
                  type={showPassword ? "text" : "password"}
                  data-test="signup-password-input"
                  id="password"
                  name="password"
                  placeholder="Enter your password"
                  value={formData.password}
                  onChange={handleChange}
                  className="bg-[#2B2B37] text-secondary-text py-[0.625rem] px-[1rem] rounded-[0.625rem]"
                />
                <EyeIcon
                  onClick={togglePasswordVisibility}
                  className="w-[1rem] h-[1rem] absolute right-[1rem] bottom-[0.5rem] cursor-pointer"
                />
              </div>

              <div className="flex flex-col w-full mt-[1.25rem] gap-[0.25rem] relative">
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input
                  type="password"
                  data-test="signup-confirm-password-input"
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Re-enter your password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  className="bg-[#2B2B37] text-secondary-text py-[0.625rem] px-[1rem] rounded-[0.625rem]"
                />
                {/* error */}
                {formData.password !== formData.confirmPassword && (
                  <p
                    className="text-[#E25354] text-[0.75rem] mt-[0.25rem] absolute right-[1rem] bottom-[-0.2rem]"
                    data-test="signup-password-error"
                  >
                    Not matched
                  </p>
                )}
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="terms"
                  data-test="signup-terms-checkbox"
                  name="terms"
                  checked={formData.terms}
                  onChange={handleChange}
                  className="form-checkbox appearance-none border border-[#6381FC] w-[1.5rem] h-[1.5rem] rounded-[0.375rem] bg-[#313541] checked:bg-[#6381FC] checked:border-[#6381FC] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#6381FC] cursor-pointer"
                />
                <p className="text-secondary-text font-poppins text-[0.875rem] font-medium leading-normal tracking-[-0.0175rem] my-[1.5rem]">
                  By signing up, you agree to our Terms and Privacy.
                </p>
              </div>
            </div>

            <div className="flex flex-col w-full">
              <button
                disabled={loading}
                type="submit"
                id="submit"
                data-test="signup-submit-button"
                className={`flex justify-center items-center gap-[0.25rem] border rounded-[6.25rem] text-white font-poppins border-[#6381FC] h-[3rem] pt-[0.5rem] pr-[1.25rem] pb-[0.5rem] pl-[0.875rem] bg-[#292749] disabled:cursor-not-allowed hover:cursor-pointer hover:scale-105 transition`}
              >
                {loading ? (
                  <>
                    <div className="rounded-full w-[0.4rem] h-[0.4rem] animate-dot1 bg-white"></div>
                    <div className="rounded-full w-[0.4rem] h-[0.41rem] animate-dot2 bg-white"></div>
                    <div className="rounded-full w-[0.4rem] h-[0.41rem] animate-dot3 bg-white"></div>
                  </>
                ) : (
                  "Signup"
                )}
              </button>
            </div>
            {error && (
              <p
                className="text-[#E25354] text-center w-[calc(20vw)] text-[0.75rem] mt-[0.25rem] flex-wrap"
                data-test="signup-error"
              >
                {error}
              </p>
            )}

            <div className="w-full">
              <div className="flex justify-center items-center">
                <hr className="h-[0.1rem] my-[1.5rem] bg-gradient-to-r from-[#1D1E2136] to-[#24262C] w-1/3" />
                <span className="text-secondary-text text-[0.875rem] tracking-[-0.0175rem]">
                  Or Sign up with
                </span>
                <hr className="h-[0.1rem] my-[1.5rem] bg-gradient-to-l from-[#1D1E2136] to-[#24262C] w-1/3" />
              </div>

              <div className="flex flex-col md:flex-row gap-4 justify-between">
                <button
                  className="flex text-text-color justify-center items-center rounded-[6.25rem] pt-[0.8125rem] pr-[3.685rem] pb-[0.9375rem] pl-[3.6875rem] bg-[rgba(255,255,255,0.08)] hover:cursor-pointer hover:scale-105 transition"
                  data-test="signup-google-button"
                >
                  <GoogleIcon className="w-[1.5rem] h-[1.5rem]" />
                  <span className="mx-2">Google</span>
                </button>

                <button
                  className="flex text-text-color justify-center items-center rounded-[6.25rem] pt-[0.8125rem] pr-[3.685rem] pb-[0.9375rem] pl-[3.6875rem] bg-[rgba(255,255,255,0.08)] hover:cursor-pointer hover:scale-105 transition"
                  data-test="signup-apple-button"
                >
                  <AppleIcon className="w-[1.5rem] h-[1.5rem]" />
                  <span className="mx-2 tracking-[-0.0225rem]">Apple</span>
                </button>
              </div>

              <p className="text-[#707178] text-[0.875rem] text-center mt-[1.5rem]">
                Already have an account?{" "}
                <span
                  className="text-[#6381FC] hover:cursor-pointer hover:underline hover:scale-105 transition"
                  data-test="signup-login-link"
                  onClick={() => navigate(Routes.LOGIN)}
                >
                  &nbsp; Login
                </span>
              </p>
            </div>
          </form>
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <SnackbarContent
          message="Sign ups are currently disabled, we will enable them soon"
          style={{
            backgroundColor: "#292749",
            color: "#fff",
            borderRadius: "8px",
            textAlign: "center",
            fontFamily: "poppins",
            transition: "ease-in-out",
          }}
        />
      </Snackbar>
    </div>
  );
};
