import React, { useState, useEffect } from "react";
import { useChat } from "../../context/ChatContext";
import { Message } from "../../types/chat.types";
import { AIBotIcon } from "../../assets";
import { Divider } from "@mui/material";

export const Chat = () => {
  const { chats, activeChat, loadingResponse } = useChat();
  const [messages, setMessages] = useState<Message[]>([]);

  useEffect(() => {
    const activeChatMessages =
      chats.find((chat) => chat.id === activeChat)?.messages || [];
    setMessages(activeChatMessages);
  }, [chats, activeChat]);

  return (
    <div
      data-test="chat-container"
      className="w-full h-[calc(65vh)] p-4 overflow-y-auto "
    >
      <div data-test="chat-messages" className="flex w-full flex-col gap-4">
        {messages.map((message) => (
          <div
            key={message.id}
            data-test={`message-${message.role}`}
            className={`flex ${
              message.role === "user" ? "justify-end" : "justify-start"
            }`}
          >
            <div
              className={`px-[1rem] py-[0.625rem] flex  gap-3 text-start rounded-lg max-w-[60%] ${
                message.role === "user" ? "bg-gray-200" : ""
              }`}
            >
              {message.role !== "user" && (
                <div>
                  <AIBotIcon className="w-[1.625rem] h-[1.825rem]" />
                </div>
              )}

              <p className="m-0 p-0 text-[1rem] text-text-color font-poppins font-normal leading-[1.75rem] tracking-[-0.02rem]">
                {message.content}
              </p>
            </div>
          </div>
        ))}
        {loadingResponse && (
          <div className="flex justify-start">
            <div className="px-[1rem] py-[0.625rem] flex gap-3 text-start rounded-lg max-w-[60%] ">
              <div>
                <AIBotIcon className="w-[1.625rem] h-[1.825rem]" />
              </div>
              <div className="flex items-center gap-1">
                <div className="rounded-full w-[0.4rem] h-[0.4rem] animate-dot1 bg-gray-500"></div>
                <div className="rounded-full w-[0.4rem] h-[0.4rem] animate-dot2 bg-gray-500"></div>
                <div className="rounded-full w-[0.4rem] h-[0.4rem] animate-dot3 bg-gray-500"></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
