import { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Markdown from "react-markdown";
import { DocumentForm } from "../../components/document/document-form";
import {
  ImgGenerate,
  ImgLineHorizontal,
  ImgLineLeft,
  ImgLineRight,
  ImgLogo,
  BurstIcon,
} from "../../assets/index";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useAuth } from "../../context/AuthContext";
import { InfiniteMovingCards } from "../../components/ui/infinite-moving-cards";
import LogoutIcon from "@mui/icons-material/Logout";
import { AppRoute } from "../../routes";

interface Question {
  question: string;
  type: "textinput" | "textarea" | "checkbox";
  placeholder: string;
}

interface QuestionSection {
  sectionTitle: string;
  questions: Question[];
}

const documents = [
  "Lease Agreement",
  "Purchase Agreement",
  "Non-Disclosure Agreement",
  "Partnership Agreement",
  "Service Level Agreement",
  "Consulting Agreement",
  "Sales Contract",
  "Employment Agreement",
];

export function HomePage() {
  const [tempDocument, setTempDocument] = useState("");
  const { logout, user } = useAuth();
  const navigate = useNavigate();

  const handleCardClick = (item: string) => {
    console.log("I got clicked", item);
    setTempDocument(item);
  };

  function handleLogout(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void {
    event.preventDefault();
    logout();
    navigate("/login");
  }
  return (
    <>
      <Helmet>
        <title>AI Frank | Home</title>
        <meta name="description" content="" />
      </Helmet>

      <div
        data-id="container"
        className="w-full max-w-full overflow-x-hidden text-center text-[1.25rem] text-text-color font-poppins overflow-hidden "
      >
        <div
          data-id="top-glow"
          // [filter:blur(500px)] rounded-[50%] bg-primary-inside  border-stroked border-[1px] border-solid box-border w-[50rem] h-[30rem] mb-[-120px] mx-auto mt-[-20rem]
          className="top-glow "
        />
        <nav className="text-center md:text-left ml-[2.5rem]">
          <ImgLogo data-id="nav-logo" className="w-[8.5rem] h-[2rem]" />
        </nav>
        <div className="absolute flex top-4 right-0 mr-[2rem] hover:cursor-pointer">
          {user && (
            <button
              data-id="dashboard-button"
              onClick={() => navigate(AppRoute.Dashboard)}
              className="  mt-[1rem] hover:cursor-pointer  mr-[2rem] rounded-[30px]  bg-primary-inside border-stroked border-[1px] border-solid  hidden md:flex flex-row items-center justify-center py-[0.5rem] px-[2rem] text-text-color font-medium font-poppins "
            >
              Dashboard
            </button>
          )}
          <button
            data-test="login-logout-button"
            onClick={handleLogout}
            className="  mt-[1rem] hover:cursor-pointer  mr-[2rem] rounded-[30px]  bg-primary-inside border-stroked border-[1px] border-solid  hidden md:flex flex-row items-center justify-center py-[0.5rem] px-[2rem] text-text-color font-medium font-poppins "
          >
            {user ? "Logout" : "Login"}
          </button>
          {/* logout icon for smaller screens */}
          <div
            className="flex justify-center md:hidden hover:cursor-pointer "
            onClick={() => {
              logout();
              navigate("/login");
            }}
          >
            <LogoutIcon className="w-[2rem] " />
          </div>
        </div>
        <div className="flex flex-col items-center  justify-start text-[1.125rem] h-[calc(50vh)]  ">
          <ImgLineLeft
            data-id="line-left"
            className="relative self-start hidden md:block top-[28rem] left-[15rem] md:mb-0 rounded-xl w-[19.25rem] h-[8.625rem] object-contain"
          />

          <div className="rounded-[30px] mt-20 md:m-0  bg-primary-inside border-stroked border-[1px] border-solid flex flex-row items-center justify-center py-[0.5rem] px-[2rem]">
            <div className="relative tracking-[-0.04em] font-medium">
              AI For Documents
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[0.5rem] text-[2rem] md:text-[3.5rem] mt-[1rem]">
            <div className="self-stretch relative tracking-[-0.06em] font-medium">
              <span>Generate</span>
              <span className="text-white">{` `}</span>
              <span className="text-transparent !bg-clip-text [background:linear-gradient(91.15deg,_#707178,_#484950)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]">{`Legal `}</span>
              <span>Documents</span>
            </div>
            <div className="self-stretch relative  text-[1.5rem] md:text-[1.25rem] tracking-[-0.04em] font-medium text-secondary-text mt-[0.5rem]">
              Legal documents Personalized for your needs.
            </div>
          </div>
          <ImgLineRight
            data-id="line-right"
            className="relative self-end hidden md:block md:top-[8rem] right-[15rem]  rounded-xl w-[19.25rem] h-[8.625rem] object-contain"
          />
        </div>

        <DocumentForm
          user={user}
          tempDocument={tempDocument}
          setTempDocument={setTempDocument}
        />

        {/* {generatedDocument && <div ref={generatedDocumentRef} className="max-w-[45rem] [backdrop-filter:blur(20px)] rounded-2xl bg-gray-500 border-gray-100 border-[1px] border-solid box-border flex flex-col items-center justify-center mx-[1rem] md:mx-auto p-[1.5rem] gap-[1rem] text-[0.875rem] mt-[2rem] text-stroked text-left">
          <Markdown className="self-stretch relative tracking-[-0.04em] font-medium">{generatedDocument}</Markdown>
        </div>} */}

        <div className="flex flex-col  items-center justify-center gap-[1.5rem] mt-[6rem] relative ">
          <div
            data-id="middle-glow"
            className="middle-glow"
            // className="absolute right-0 left-0 [filter:blur(250px)] border border-stroked bg-primary-inside rounded-[48.75rem]  md:w-[48.75rem] max-w-screen h-[30.5625rem] mx-auto z-[-10]"
          />
          <p className="text-center text-[1.25rem] text-text-color font-poppins font-semibold leading-normal tracking-[-0.05rem]">
            AIFrank Document Samples
          </p>

          <div className="flex flex-col gap-8 mb-[6rem]">
            <InfiniteMovingCards
              items={documents}
              direction="left"
              speed="normal"
              onCardClick={handleCardClick}
            />

            <InfiniteMovingCards
              items={documents}
              direction="right"
              speed="normal"
              onCardClick={handleCardClick}
            />
          </div>
        </div>
      </div>
    </>
  );
}
