import { useState, useRef, useEffect } from "react";
import { PdfIcon, AttachIcon, StarInputIcon, SendIcon } from "../../assets";
import { Divider } from "@mui/material";
import { useChat } from "../../context/ChatContext";
import { Chat } from "../../types/chat.types";

export const Input = () => {
  const [files, setFiles] = useState<File[] | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null); // Ref for file input
  const [userPrompt, setUserPrompt] = useState("");
  const { chats, setChats, activeChat, loadingResponse, setLoadingResponse } =
    useChat();

  // Listen for enter key
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        handleSendChat();
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [userPrompt, activeChat]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = event.target.files ? Array.from(event.target.files) : []; // Convert FileList to Array
    setFiles((prevFiles) =>
      prevFiles ? [...prevFiles, ...newFiles] : newFiles
    ); // Append new files to existing files
  };

  const sendMessage = async (chatId: string, userMessage: string) => {
    setChats((prevChats) =>
      prevChats.map((chat) => {
        if (chat.id === chatId) {
          return {
            ...chat,
            messages: [
              ...chat.messages,
              {
                id: crypto.randomUUID(), // Generate a unique ID for the message
                role: "user",
                content: userMessage,
                timestamp: new Date(),
              },
            ],
            updatedAt: new Date(), // Update the last activity timestamp
          } as Chat;
        }
        return chat;
      })
    );

    try {
      const openAIKey = process.env.REACT_APP_OPENAI_API_KEY;

      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${openAIKey}`,
          },
          body: JSON.stringify({
            model: "gpt-3.5-turbo",
            messages: [{ role: "user", content: userMessage }],
          }),
        }
      );

      const aiResponse = await response.json();
      const botMessageContent = aiResponse.choices[0].message.content;

      setChats((prevChats) =>
        prevChats.map((chat) => {
          if (chat.id === chatId) {
            return {
              ...chat,
              messages: [
                ...chat.messages,
                {
                  id: crypto.randomUUID(), // Generate a unique ID for the bot message
                  role: "assistant",
                  content: botMessageContent,
                  timestamp: new Date(),
                },
              ],
              updatedAt: new Date(),
            } as Chat;
          }
          return chat;
        })
      );
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setLoadingResponse(false);
    }
  };

  const handleSendChat = () => {
    if (activeChat && userPrompt) {
      setLoadingResponse(true);
      sendMessage(activeChat, userPrompt);
      setUserPrompt("");
    }
  };

  return (
    <div className="w-[80%] mb-4 bg-gray-200 rounded-[0.75rem] pl-[0.75rem]  ">
      {/* File Preview Section */}

      {files && files.length > 0 && (
        <>
          <div className="overflow-x-auto custom-scrollbar pt-[0.5rem]">
            <div className="flex w-fit gap-2  ">
              {files.map((file, index) => (
                <div
                  key={index}
                  className="flex relative items-center justify-between bg-gray-700 rounded-[0.75rem] w-[15rem] overflow-hidden px-[1rem] py-[0.625rem]"
                >
                  <div className="flex items-center gap-[0.5rem]">
                    <PdfIcon className="w-[1.75rem] h-[1.75rem]" />
                    <div className="flex flex-col text-start p-0 m-0 w-[50%] overflow-hidden ">
                      <p className="text-sm font-medium truncate p-0 m-0 tracking-[-0.0175rem]">
                        {file.name}
                      </p>
                      <p className="text-xs text-text-color p-0 m-0">
                        {(file.size / 1024).toFixed(2)} KB
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={() =>
                      setFiles(files.filter((_, i) => i !== index))
                    }
                    className="bg-gray-200 absolute w-[1rem] h-[1rem] top-[0.25rem] right-[0.25rem] flex items-center justify-center border-2 border-white text-white text-xs  rounded-full"
                  >
                    x
                  </button>
                </div>
              ))}
            </div>
          </div>
          <Divider
            sx={{
              backgroundColor: "var(--gray-700)",
              marginX: "0.5rem",
              marginTop: "0.5rem",
            }}
          />
        </>
      )}

      <div className="flex justify-between items-center pl-3 w-fullrounded-[0.75rem] h-[3.25rem]  ">
        <div className="flex flex-row justify-center items-center gap-[0.5rem]">
          <div
            className="h-full flex items-center hover:cursor-pointer"
            onClick={() => fileInputRef.current?.click()}
          >
            <input
              className="appearance-none hidden"
              type="file"
              ref={fileInputRef}
              multiple={true}
              onChange={handleFileChange}
            />
            <AttachIcon className="w-[1.5rem] h-[1.5rem]" />
          </div>
          <hr className="h-[1.5rem]  w-[0.1rem] bg-darkslategray" />
          <StarInputIcon className="w-[1.5rem] h-[1.5rem] " />
        </div>
        <input
          type="text"
          className="w-[90%] pl-2 text-white bg-gray-200 outline-none"
          placeholder="Ask AI Frank.."
          value={userPrompt}
          onChange={(e) => setUserPrompt(e.target.value)}
        />
        <div className="bg-stroked self flex items-center justify-center  p-[0.75rem] mr-[0.3rem] rounded-[0.75rem]">
          <SendIcon
            className="w-[1.04938rem] h-[1.04938rem]  "
            onClick={handleSendChat}
          />
        </div>
      </div>
    </div>
  );
};
