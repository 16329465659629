import React, { createContext, ReactNode, useState } from "react";
import { Chat, Message } from "../types/chat.types";

interface ChatContextType {
  activeChat: string | null; // ID of the active chat
  setActiveChat: React.Dispatch<React.SetStateAction<string | null>>;
  chats: Chat[]; // Array of chat sessions
  setChats: React.Dispatch<React.SetStateAction<Chat[]>>;
  loadingResponse: boolean;
  setLoadingResponse: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ChatContext = createContext<ChatContextType | undefined>(
  undefined
);

export const ChatProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [activeChat, setActiveChat] = useState<string | null>(null);
  const [chats, setChats] = useState<Chat[]>([]);
  const [loadingResponse, setLoadingResponse] = useState<boolean>(false);

  return (
    <ChatContext.Provider
      value={{
        activeChat,
        setActiveChat,
        chats,
        setChats,
        loadingResponse,
        setLoadingResponse,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = (): ChatContextType => {
  const context = React.useContext(ChatContext);
  if (context === undefined) {
    throw new Error("useChat must be used within a ChatProvider");
  }
  return context;
};
